import { TextField } from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import formStyles from "../EqxForm/EqxForm.module.scss";
import styles from "./EqxTextInput.module.scss";

const EqxTextInput = ({
  className,
  inputProps,
  label,
  type,
  helperText,
  fieldName,
  hasParentError,
}) => {
  const { control, trigger } = useFormContext();
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  // Phone number formatting function (for display)
  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, ""); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      const part1 = match[1] ? `(${match[1]}` : "";
      const part2 = match[2] ? `) ${match[2]}` : "";
      const part3 = match[3] ? `-${match[3]}` : "";
      return `${part1}${part2}${part3}`;
    }

    // Match international UK number with country code (e.g., 44 followed by 10 digits)
    const matchIntlUK = cleaned.match(/^44(\d{4})(\d{6})$/);

    if (matchIntlUK) {
      return `+44 ${matchIntlUK[1]} ${matchIntlUK[2]}`; // Format as +44 1234 567890
    }

    // Match local UK number (starts with 07 followed by 10 digits)
    const matchLocalUK = cleaned.match(/^(07\d{3})(\d{6})$/);

    if (matchLocalUK) {
      return `${matchLocalUK[1]} ${matchLocalUK[2]}`; // Format as 07123 456789
    }

    return value; // Return unformatted if no match
  };

  // Function to strip the formatting (for raw value)
  const stripPhoneNumberFormatting = (value) => {
    return value.replace(/\D/g, ""); // Remove non-numeric characters
  };

  return (
    <Controller
      control={control}
      defaultValue=""
      name={fieldName ? fieldName : label}
      render={({
        field: { onBlur, onChange, value },
        fieldState: { error },
      }) => (
        <TextField
          aria-invalid={!!error || hasParentError}
          className={classNames(formStyles.formField, styles.input, className)}
          data-is="EqxTextInput"
          error={!!error || hasParentError}
          fullWidth={true}
          helperText={
            !!error || hasParentError ? "Fill out to continue" : helperText
          }
          inputProps={inputProps}
          label={label}
          onBlur={onBlur}
          onChange={(e) => {
            let rawValue = e.target.value;
            if (type == "tel") {
              // Strip the formatting for storing raw value
              rawValue = stripPhoneNumberFormatting(rawValue);
            }
            onChange(rawValue); // update the form value
            trigger(fieldName || label); // trigger validation onChange
          }}
          type={type}
          value={type == "tel" ? formatPhoneNumber(value) : value}
          variant="outlined"
        />
      )}
      rules={{ required: true }}
    />
  );
};

EqxTextInput.propTypes = {
  className: PropTypes.string,
  fieldName: PropTypes.string,
  hasParentError: PropTypes.bool,
  helperText: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
};

EqxTextInput.defaultProps = {
  type: "text",
};

export default EqxTextInput;
