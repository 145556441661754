import { TextField } from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useFormContext } from "react-hook-form";
import DatePickerStyles from "src/components/datepicker/index.module.scss";

import CalendarIcon from "@/assets/icons/calendar-icon.svg";

import formStyles from "../EqxForm/EqxForm.module.scss";
import styles from "./EqxDatePicker.module.scss";

const InputDatePicker = React.forwardRef((props, ref) => {
  const isWithin30Days = (date) => {
    const aMonthFromToday = new Date(); // Today
    aMonthFromToday.setDate(aMonthFromToday.getDate() + 29); // Today + 29 days (Because today is Day 1)

    const yesterday = new Date(); // Today
    yesterday.setDate(yesterday.getDate() - 1); // Today - 1 Day

    const isNotBeforeToday = new Date(date) > yesterday; // Evaluated Date is > Yesterday
    const isNotAMonthFromToday = aMonthFromToday > new Date(date); // Today + 29 Days > Evaluated Date
    return isNotBeforeToday && isNotAMonthFromToday;
  };
  const formatDateValue = (value) =>
    new Date().toDateString() === new Date(value).toDateString()
      ? "Today"
      : value;

  return (
    <div className={styles.calendarWrapper}>
      <input className={styles.inputHidden} ref={ref} value={props.value} />
      <DatePicker
        calendarClassName={classNames(
          DatePickerStyles.calendarWrapper,
          styles.calendarWrapper
        )}
        className={classNames(
          DatePickerStyles.input,
          props.className,
          "pt-2",
          "pb-1"
        )}
        dateFormat="MMMM dd, yyyy"
        dayClassName={(date) => {
          if (
            (!props.value &&
              new Date(date).toDateString() === new Date().toDateString()) ||
            (!!props.value &&
              new Date(date).toDateString() ===
                new Date(props.value).toDateString())
          ) {
            return DatePickerStyles.selectedDay;
          }
        }}
        disabled={false}
        disabledKeyboardNavigation
        filterDate={props.filterDate ? props.filterDate : isWithin30Days}
        onChange={props.onChange}
        popperClassName={DatePickerStyles.zIndex}
        selected={props.value}
        shouldCloseOnSelect={true}
        value={formatDateValue(props.value)}
        weekDayClassName={() => DatePickerStyles.weekDay}
        wrapperClassName={DatePickerStyles.datepickerWidth}
      />
      <CalendarIcon
        className={classNames(
          DatePickerStyles.calendarIcon,
          styles.calendarIcon
        )}
      />
    </div>
  );
});

InputDatePicker.propTypes = {
  className: PropTypes.string,
  filterDate: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

const EqxDatePicker = ({
  className,
  label,
  fieldName,
  inputProps,
  defaultValue,
}) => {
  const innerRef = useRef();
  const { control } = useFormContext();
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <Controller
      control={control}
      defaultValue={defaultValue ? defaultValue : ""}
      name={fieldName ? fieldName : label}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          InputProps={{
            inputComponent: InputDatePicker,
          }}
          className={classNames(formStyles.formField, styles.input, className)}
          data-is="EqxDatePicker"
          defaultValue={defaultValue}
          error={!!error}
          fullWidth={true}
          inputProps={inputProps}
          inputRef={innerRef}
          label={label}
          onChange={onChange}
          value={value}
          variant="outlined"
        />
      )}
      rules={{ required: true }}
    />
  );
};

EqxDatePicker.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  fieldName: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.string.isRequired,
};

InputDatePicker.displayName = "InputDatePicker";

export default EqxDatePicker;
